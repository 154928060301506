export const colors = {
  background: {
    white: '#ffffff',
    primaryBlack: '#1B1C1F',
    primaryTab: '#CCE6E6',
    inputBox: '#F7F7F7',
    primaryBackground: '#F5F5F5',
    secondaryBackground: '#FBFBFD',
    primaryGray: '#CECECE',
    lightCyan: '#D4FFFF',
    approxWhiteSmoke: '#F6F6F6',
    approxAliceBlue: '#F7F9FA',
    imageDefaultBackground: '#5D6E6E'
  },
  text: {
    primaryBlack: '#1C1B1F',
    secondaryBlack: '#393939',
    tertiaryBlack: '#2F2924',
    quaternaryBlack: '#232323',
    primaryBlue: '#9E52FF',
    primaryOrange: '#F6863A',
    primaryTheme: '#028383',
    secondaryTheme: '#1EA1B2',
    primaryGray: '#F2F2F2',
    secondaryGray: 'rgba(101, 93, 83, 0.60)',
    primaryWhite: '#ffffff',
    brightBlue: '#1D7791',
    darkBlack: '#000000',
    darkGray: '#616161',
    grey: '#DADADA',
    blackGray: '#303030',
    lightShadeGray: '#EBFFFF',
    approxIvory: '#F8FFFF',
    titanWhite: '#d5d4d9',
    approxWhiteSmoke: '#F4F4F4',
    approxGainsboro: '#d9d9d9',
    approxGainGray: '#E1E1E1',
    lightShade: '#DDDDDD',
    approxLightShade: '#EFEFEF',
    approxSuvaGrey: '#898989',
    approxNero: '#282828',
    approxVeryLightGrey: '#C8C8C8',
    approxAliceBlue: '#EFFCFC',
    deleteComment:' #9F9F9F'
  },
  shadow: {
    primary: '#B8B8B8'
  },
  border: {
    secondaryGray: '#A1A1A1',
    tertiaryTheme: '#1EA2AF',
    primary: '#F0F0F0'
  },
  borderColor: {
    primaryDust: '#A1A1A1'
  },
  error: {
    primaryRed: '#FF0000'
  },
  placeHolder: {
    input: '#89888A'
  },
  linkBlue: '#279FBA'
};
