import React, { useState } from 'react';
import { Avatar, SxProps } from '@mui/material';
import { colors } from 'styles/colors';
import FullScreenView from './fullScreenView';
import DisableZoom from './DisableZoom';
import { IMediaItems } from './carousalItems';

export type nameVariant = 'xs' | 'sm' | 'md' | 'lg';
interface IAvatarImgProps {
  src: string;
  alt: string;
  id: string;
  width?: string | number;
  height?: string | number;
  name?: string;
  showBorder?: boolean;
  showFilter?: boolean;
  enableLetterImg?: boolean;
  style?: React.CSSProperties;
  nameStyle?: SxProps;
  nameVariant?: nameVariant;
  isLoggedInUserProfile?:boolean;
}

const AvatarImg: React.FC<IAvatarImgProps> = ({
  src,
  alt,
  id,
  width,
  height,
  showBorder,
  showFilter,
  name = '',
  enableLetterImg = true,
  nameStyle,
  nameVariant = 'sm',
  isLoggedInUserProfile,
  ...props
}) => {
  const dimension = {
    width: width,
    height: height
  };


  const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const getInitials = (text: string) => {
    const word = text
      .toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase())
      .join('');
    return word;
  };

  const stringAvatar = (name: string) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
        fontSize: 8,
        ...dimension,
        ...nameStyle
      },
      children: getInitials(name).substring(0, 2)
    };
  };

  const [imgLoad, setImageLoad] = useState<boolean>(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [mediaItems, setMediaItems] = useState<IMediaItems[]>([]);

  const onImageClick = (src: any) => {
    const image = {directUrl:src, fileName:name}
    setMediaItems([image])
    setFullScreen(true);
  }

  return (
    <>
   
      {!enableLetterImg && !imgLoad ? (
        <>
          <img
            alt={name}
            id={id}
            data-testid={id}
            src={src}
            {...props}
            style={{
              ...dimension,
              background: colors.text.lightShade,
              cursor:'pointer',
              ...props.style
            }}
            onError={() => {
              setImageLoad(true);
            }}
            onClick={() => onImageClick(src)}
          />
          {fullScreen && isLoggedInUserProfile && (
            <FullScreenView
              fullscreen={fullScreen}
              mediaItems={mediaItems}
              activeIndex={0}
              enableZoom={false} // Zoom functionality disabled
              closeFullScreen={() => {
                setFullScreen(false);
                setMediaItems([]);
              }}
            />
          )}
          <DisableZoom isEnabled={fullScreen}/>
        </>
      ) : (
        <Avatar {...stringAvatar(name)} />
      )}
    </>
  );
};

export default AvatarImg;
