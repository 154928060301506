import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, Button, TextField, InputAdornment, Snackbar, Alert, ClickAwayListener, Paper, MenuList, MenuItem } from '@mui/material';
import { BackArrowIcon } from 'assets/icon';
import { ApiPaths, ApiPaths_Groups } from 'models/apiPaths';
import { axiosPostFriendsList, axiosPostGroupSearch, fetchUserDetails } from 'services/api';
import { IFriends } from 'models/userProfile';
import TypographyWithI18N from 'pages/components/TypographyWithI18N';
import { colors } from 'styles/colors';
import { CloseRoundIcon, SearchIcon } from 'assets';
import AvatarImg from 'pages/components/avatarImg';
import { getUrl, groupImgUrl } from 'utilities/userImageURL';
import { Check } from '@mui/icons-material';
import InfiniteScroll from 'react-infinite-scroll-component';
import GroupsSkeleton from '__mocks__/skeletonLoading/groupsLoader';
import { IUserGroupSearch } from 'models/groups';

interface IAddParticipantsProps {
    onClose: (selectedFriends: any[]) => void;
    existingParticipantIds: string[];
    selectedFriends: IFriends[];
}

const AddParticipants: React.FC<IAddParticipantsProps> = ({ onClose, existingParticipantIds, selectedFriends: parentSelectedFriends }) => {
    const [scroll, setScroll] = useState<boolean>(true);
    const [friendsData, setFriendsData] = useState<any[]>([]);
    const [selectedUsers, setSelectedUsers] = React.useState<any[]>([]);
    const [pageChange, setPageChange] = useState({
        previousPageCursor: '',
        nextPageCursor: '',
        nextPage: true
    });
    const [userGroupQuery, setUserGroupQuery] = useState('');
    const [loader, setLoader] = useState<boolean>(true);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [userId, setUserId] = useState<string>("");
    const [showGroupList, setShowGroupList] = useState<boolean>();
    const [userGroupList, setUserGroupList] = useState<any[]>([]);

    const handleUserSelection = (user: any) => {
        setSelectedUsers((prevSelected) => {
            const userExists = prevSelected.some((selectedUser) => selectedUser.user.id === user.user.id);

            if (userExists) {
                return prevSelected.filter((selectedUser) => selectedUser.user.id !== user.user.id);
            } else {
                if (prevSelected.length >= 6) {
                    setDialogOpen(true);
                    return prevSelected;
                }
                return [...prevSelected, user];
            }
        });
    };

    const getFriendsList = async (user: any) => {
        if (pageChange?.nextPage) {
            setLoader(true);
            const payload = {
                size: 20,
                nextPageCursor: pageChange?.nextPageCursor ?? ''
            };
            await axiosPostFriendsList(payload, user)
                .then((res: IFriends) => {
                    if (res) {
                        setPageChange({
                            previousPageCursor: res.previousPageCursor,
                            nextPageCursor: res.nextPageCursor,
                            nextPage: res.nextPageCursor ? true : false
                        });
                        setFriendsData((pv) => [...pv, ...res.content]);

                        const preselectedUsers = res.content.filter((user) =>
                            existingParticipantIds.includes(user.user.id)
                        );

                        setSelectedUsers((prevSelected) => {
                            const updatedSelectedUsers = [...prevSelected];
                            preselectedUsers.forEach(user => {
                                if (!updatedSelectedUsers.some(selected => selected.user.id === user.user.id)) {
                                    updatedSelectedUsers.push(user);
                                }
                            });
                            return updatedSelectedUsers;
                        });
                    }
                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    setLoader(false);
                });
        }
    };


    const userGroupItems = (items: IUserGroupSearch[]) => {
        return items
            .filter((_itm) => _itm.user)
            .map((_itm) => {
                const userActive = Boolean(_itm.user);
                return {
                    userActive: userActive,
                    id: _itm.user?.id,
                    name: _itm.user?.username,
                    imageId: _itm.user?.userImage?.id,
                    imageUrl: _itm.user?.userImage?.id ? getUrl(_itm.user?.userImage?.id) : '',
                };
            });
    };

    const handleUserGroupSearch = async (text: string) => {
        setUserGroupQuery(text);
        const payload = {
            keyword: text,
            enforceGroupMembership: true,
            enforceFriendship: true
        };
        setShowGroupList(true);
        try {
            const response = await axiosPostGroupSearch(
                ApiPaths_Groups.GROUP_USER_SEARCH,
                payload
            );
            response && setUserGroupList(userGroupItems(response));
        } catch (error) {
            console.log(error);
            setShowGroupList(false);
        }
    };

    const resetUserGroup = () => {
        setUserGroupList([]);
        setUserGroupQuery('');
        setShowGroupList(false);
    };

    useEffect(() => {
        fetchUserDetails(ApiPaths.ME).then((data) => {
            setUserId(data.id);
            getFriendsList(data.id);
        });
    }, []);


    const handleSelectButton = () => {
        onClose(selectedUsers);
    };

    const handleBackButton = () => {
        onClose(parentSelectedFriends);
    };

    useEffect(() => {
        setSelectedUsers(parentSelectedFriends);
    }, [parentSelectedFriends]);


    return (
        <Box>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: 2,
                borderBottom: `1px solid ${colors.border.primary}`
            }}>
                <Box sx={{
                    display: "flex",
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                }}>
                    <IconButton onClick={handleBackButton}>
                        <BackArrowIcon />
                    </IconButton>
                    <TypographyWithI18N
                        className='smallHeader'
                        i18nKey={'Select Friends'}
                        sx={{
                            color: colors.text.primaryBlack,
                            whiteSpace: 'nowrap'
                        }}
                    />
                </Box>
                {/* <Box sx={{ position: 'relative', ml: 2 }}>
                    <TextField
                        placeholder='Search...'
                        InputProps={{
                            sx: {
                                height: 40,
                                pr: 0,
                                borderRadius: '30px',
                                background: '#EFEFEF',
                                '& fieldset': { border: 'none' }
                            },
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <>
                                    {userGroupQuery.length > 0 && (
                                        <InputAdornment
                                            position='start'
                                            sx={{ cursor: 'pointer' }}
                                            onClick={resetUserGroup}
                                        >
                                            <CloseRoundIcon />
                                        </InputAdornment>
                                    )}
                                </>
                            )
                        }}
                        value={userGroupQuery}
                        onChange={(event) => {
                            handleUserGroupSearch(event.target.value);
                        }}
                    />
                    {showGroupList && (
                        <ClickAwayListener onClickAway={resetUserGroup}>
                            <Paper sx={{ position: 'absolute', top: 40, width: '100%', zIndex: 500 }}>
                                <MenuList sx={{ maxHeight: 250, overflowY: 'auto', px: 1.5 }}>
                                    {userGroupList.length > 0 &&
                                        userGroupList.map((_itm, index) => (
                                            <MenuItem
                                                key={index.toString()}
                                                onClick={() => {
                                                    resetUserGroup();
                                                }}
                                                sx={{
                                                    borderTop:
                                                        index === 0
                                                            ? 0
                                                            : `1px solid ${colors.border.primary}`,
                                                    padding: 1.5
                                                }}
                                            >
                                                <AvatarImg
                                                    id='userImg'
                                                    enableLetterImg={_itm?.imageId ? false : true}
                                                    alt={_itm?.name}
                                                    src={_itm?.imageId ? _itm.imageUrl : ''}
                                                    width={32}
                                                    height={32}
                                                    name={_itm?.name}
                                                    nameStyle={{
                                                        fontSize: 12
                                                    }}
                                                    style={{
                                                        objectFit: 'cover',
                                                        borderRadius: '50%'
                                                    }}
                                                />
                                                <TypographyWithI18N
                                                    className='bodyText'
                                                    i18nKey={_itm.name}
                                                    ml={1}
                                                    color={colors.text.approxNero}
                                                    sx={{
                                                        maxWidth: '85%',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}
                                                />
                                            </MenuItem>
                                        ))}
                                    {userGroupList.length === 0 && (
                                        <MenuItem>No search users</MenuItem>
                                    )}
                                </MenuList>
                            </Paper>
                        </ClickAwayListener>
                    )}
                </Box> */}
            </Box>
            <Box sx={{ overflowX: 'auto' }}>
                <InfiniteScroll
                    dataLength={friendsData?.length}
                    next={() => getFriendsList(userId)}
                    hasMore={pageChange?.nextPage}
                    loader={loader && <GroupsSkeleton showSubLine={1} />}
                    scrollableTarget='scrollToElement'
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 16
                    }}
                    height={400}
                >
                    <Box sx={{ padding: 2 }}>
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
                                gap: '40px',
                            }}
                        >
                            {friendsData.map((user, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        position: 'relative',
                                        textAlign: 'center',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => handleUserSelection(user)}
                                >
                                    <Box sx={{ position: 'relative', display: 'inline-block', justifyItems: 'center', alignContent: 'center', height: 60, width: 60 }}>
                                        <AvatarImg
                                            key={index}
                                            id='userImg'
                                            enableLetterImg={user.user.imageId ? false : true}
                                            alt={user.username}
                                            src={getUrl(user.user.imageId)}
                                            name={user.user.username}
                                            nameStyle={{
                                                fontSize: 16,
                                            }}
                                            width={50}
                                            height={50}
                                            style={{
                                                objectFit: 'cover',
                                                borderRadius: '50%',
                                                border: `2px solid ${colors.border.primary}`,
                                            }}
                                        />
                                        {selectedUsers.some((selectedUser) => selectedUser.user.id === user.user.id) && (
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 0,
                                                    backgroundColor: 'green',
                                                    color: 'white',
                                                    borderRadius: '50%',
                                                    width: 20,
                                                    height: 20,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    border: `2px solid ${colors.background.lightCyan}`,
                                                }}
                                            >
                                                <Check fontSize="small" />
                                            </Box>
                                        )}
                                    </Box>
                                    <Box sx={{
                                        justifyItems: 'center',
                                        marginTop: 1
                                    }}>
                                        <Typography sx={{
                                            color: colors.text.primaryBlack,
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            width: 100,
                                            textOverflow: 'ellipsis',
                                        }}>{user.user.username}</Typography>
                                    </Box>

                                </Box>
                            ))}
                        </Box>
                    </Box>
                </InfiniteScroll>
            </Box>
            <Box mt={2} display="flex" justifyContent="center">
                <Button variant="contained" color="primary" sx={{ mr: 2 }} onClick={handleSelectButton}>
                    Select
                </Button>
                <Button variant="outlined" onClick={handleBackButton}>
                    Cancel
                </Button>
            </Box>
            {dialogOpen &&
                <Snackbar open={dialogOpen} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }} autoHideDuration={2000} onClose={() => setDialogOpen(false)}>
                    <Alert
                        onClose={() => setDialogOpen(false)}
                        severity="error"
                        variant="filled"
                        sx={{ width: '60%' }}
                    >
                        You already added 7 members to the conversation. Adding more members is not allowed.
                    </Alert>
                </Snackbar>
            }
        </Box>
    );
};

export default AddParticipants;