import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HubConnection } from '@microsoft/signalr';

interface SignalRState {
  connectionId: string | null;
  username: string | null;
  userStatus: any;
  messages: any[];
  deliveredMessages: any[];
  groupMessages: any[];
  isConnected: boolean;
  connection: HubConnection | null;
}

const initialState: SignalRState = {
  connectionId: null,
  username: null,
  userStatus: {},
  messages: [],
  deliveredMessages: [],
  groupMessages: [],
  isConnected: false,
  connection: null
};

const signalrSlice = createSlice({
  name: 'signalr',
  initialState,
  reducers: {
    setUsername(state, action: PayloadAction<string>) {
      state.username = action.payload;
    },
    setConnectionId(state, action: PayloadAction<string | null>) {
      state.connectionId  = action.payload;
      state.isConnected = true;
    },
    setConnection(state, action: PayloadAction<HubConnection | null>) {
      state.connection = action.payload;
    },
    updateActiveUsers(state, action: PayloadAction<{ userid: string; isonline: boolean }>) {
      state.userStatus = action.payload;
    },
    addMessage(state, action: PayloadAction<string[]>){
      state.messages = action.payload;
    },
    clearMessages(state) {
      state.messages = [];
    },
    updateMessageStatus(state, action: PayloadAction<string[]>){
      state.deliveredMessages = action.payload;
    },
    addGroupMessage(state, action: PayloadAction<string[]>){
      state.groupMessages = action.payload;
    },
    clearGroupMessages(state) {
      state.groupMessages = [];
    },
    clearConnection(state) {
      state.connectionId = null;
      state.isConnected = false;
      state.connection = null;
    },
  },
});

export const {
  setUsername,
  setConnectionId,
  setConnection,
  updateActiveUsers,
  addMessage,
  clearMessages,
  updateMessageStatus,
  addGroupMessage,
  clearGroupMessages,
  clearConnection,
} = signalrSlice.actions;

export default signalrSlice.reducer;
