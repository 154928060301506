import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';

interface IVideoPlayer {
  url: string;
  pauseVideoChange: boolean;
  pauseScrollChange?: boolean;
  videoSx?: React.CSSProperties;
}

const CustomVideoPlayer: React.FC<IVideoPlayer> = ({
  url,
  pauseVideoChange,
  pauseScrollChange,
  videoSx
}) => {
  const [playing, setPlaying] = useState<boolean>(false);
  const playerRef = useRef<ReactPlayer | null>(null);

  const pauseVideo = () => {
    playerRef?.current?.getInternalPlayer()?.pause();
    setPlaying(false);
  };

  const startVideo = () => {
    playerRef?.current?.getInternalPlayer()?.play();
    setPlaying(true);
  };

  const handleVideoPress = () => {
    if (playing) {
      pauseVideo();
    } else {
      startVideo();
    }
  };

  useEffect(() => {
    pauseVideo();
  }, [pauseVideoChange, pauseScrollChange]);

  return (
    <div style={{ width: '100%', ...videoSx }}>
      <ReactPlayer
        ref={playerRef}
        url={url}
        playing={playing}
        controls
        width='100%'
        height={videoSx?.height}
        onClick={handleVideoPress}
      />
    </div>
  );
};

export default CustomVideoPlayer;
