import { Box, CardMedia } from '@mui/material';
import React, { useState, useCallback, useMemo } from 'react';
import Carousel from 'react-material-ui-carousel';
import { useInView } from 'react-intersection-observer';
import { CarouselProps } from 'react-material-ui-carousel/dist/components/types';
import { userImgUrl } from 'utilities/userImageURL';
import CustomVideoPlayer from './customVideoPlayer';

export type IMediaItems = {
  fileName: string;
  directUrl: string;
  [key: string]: any;
};

interface Props extends CarouselProps {
  mediaDtos: IMediaItems[];
  scrolling?: boolean;
  activeIndex?: number;
  setActiveIndex?: (value: number) => void;
  setFullScreen?: (value: boolean) => void;
  setMediaItems?: (mediaItems: IMediaItems[]) => void;
  setVideoMedia?: (value: boolean) => void;
  imgSx?: React.CSSProperties;
  videoSx?: React.CSSProperties;
}

const LazyImage = React.memo(
  ({ src, imgSx }: { src: string; imgSx?: React.CSSProperties }) => {
    const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

    return (
      <Box
        ref={ref}
        sx={{
          position: 'relative',
          width: '100%',
          height: 'auto',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <CardMedia
          component='img'
          image={inView ? src : ''}
          loading='lazy'
          sx={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 1,
            opacity: 0.3,
            transform: 'scale(1.2)',
            filter: 'blur(24px)',
            transition: 'opacity 0.3s ease-in-out',
            ...imgSx
          }}
        />

        <CardMedia
          component='img'
          image={inView ? src : ''}
          loading='lazy'
          sx={{
            width: 'auto',
            height: 'auto',
            maxWidth: '100%',
            objectFit: 'contain',
            position: 'relative',
            zIndex: 2,
            opacity: inView ? 1 : 0,
            transition: 'opacity 0.5s ease-in-out',
            ...imgSx
          }}
        />
      </Box>
    );
  }
);

const CarousalItems = ({
  mediaDtos,
  scrolling = false,
  activeIndex,
  setActiveIndex,
  setFullScreen,
  setMediaItems,
  setVideoMedia,
  imgSx,
  videoSx,
  ...carouselProps
}: Props) => {
  const [activePlay, setActivePlay] = useState<number>(0);

  const isVideo = useCallback(
    (fileName: string) =>
      ['.mp4', '.mov'].some((ext) => fileName.toLowerCase().endsWith(ext)),
    []
  );

  const getImageUrl = useCallback(
    (item: IMediaItems) => item.directUrl || userImgUrl(item.id),
    []
  );

  const handleSelect = useCallback((index?: number) => {
    if (index !== undefined) {
      setActivePlay(index);
    }
  }, []);

  return (
    <Carousel
      onChange={handleSelect}
      index={activeIndex}
      navButtonsAlwaysVisible={mediaDtos.length > 1}
      indicators={mediaDtos.length > 1}
      cycleNavigation={false}
      autoPlay={false}
      animation='slide'
      navButtonsWrapperProps={{ style: { top: '45%', height: 'auto' } }}
      {...carouselProps}
    >
      {mediaDtos.map((item, index) => {
        const mediaUrl = getImageUrl(item);
        const isMediaVideo = isVideo(item.fileName);

        return (
          <Box
            key={index}
            sx={{
              height: 'auto',
              display: 'flex',
              justifyContent: 'center',
              cursor: 'pointer'
            }}
            onClick={(e) => {
              e.stopPropagation();
              setFullScreen?.(true);
              setMediaItems?.(mediaDtos);
              setActiveIndex?.(index);
              setVideoMedia?.(isMediaVideo);
            }}
          >
            {!isMediaVideo ? (
              <LazyImage src={mediaUrl} imgSx={imgSx} />
            ) : (
              <CustomVideoPlayer
                url={mediaUrl}
                pauseVideoChange={activePlay === index}
                pauseScrollChange={scrolling}
                videoSx={videoSx}
              />
            )}
          </Box>
        );
      })}
    </Carousel>
  );
};

export default React.memo(CarousalItems);
