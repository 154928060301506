import { Box, CardHeader, Skeleton } from '@mui/material';
import { colors } from 'styles/colors';
interface IGroupsSkeleton {
    showSubLine?: number;
    showChatSkeleton? : number;
}

const ChatsSkeleton = ({ showSubLine = 2, showChatSkeleton = 5 }: IGroupsSkeleton) => {
    return (
        <>
            {
                <CardHeader
                    sx={{
                        borderBottom: `1px solid ${colors.border.primary}`,
                        '& .MuiCardHeader-action': {
                            margin: 0
                        }
                    }}
                    avatar={
                        <Skeleton
                            animation='wave'
                            variant='circular'
                            width={40}
                            height={40}
                        />
                    }
                    title={
                        <Skeleton
                            animation='wave'
                            height={20}
                            width='40%'
                        />
                    }
                    subheader={
                        <>
                            {[...Array(showSubLine)].map((_itm, indexSub) => (
                                <Skeleton
                                    animation='wave'
                                    height={20}
                                    width='20%'
                                    key={indexSub.toString()}
                                />
                            ))}
                        </>
                    }
                />
            }
            {[...Array(showChatSkeleton)].map((row, rowIndex) => (
                <CardHeader
                    sx={{
                        paddingBottom: 0,
                    }}
                    key={rowIndex}
                    title={
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: rowIndex % 2 === 0 ? 'flex-start' : 'flex-end'
                            }}
                        >
                            <Skeleton
                                animation="wave"
                                height={60}
                                width="40%"
                                style={{ transform: 'scale(1, 1)', borderRadius: rowIndex % 2 != 0 ? '30px 30px 0 30px' : '30px 30px 30px 0', }}
                            />
                        </Box>
                    }
                />                
            ))}
        </>
    );
};

export default ChatsSkeleton;
