import { Dialog, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CarousalItems, { IMediaItems } from './carousalItems';
import { useState, useEffect } from 'react';
import { ZoomShrink, ZoomExpand, ZoomIn, ZoomOut, ZoomReset } from 'assets/icon';

type Props = {
  mediaItems: IMediaItems[];
  activeIndex?: number;
  fullscreen: boolean;
  closeFullScreen: () => void;
  videoMedia?: boolean;
  enableZoom: boolean; // Control zoom functionality
};

const FullScreenView = ({
  mediaItems,
  activeIndex = 0,
  fullscreen,
  closeFullScreen,
  videoMedia,
  enableZoom
}: Props) => {
  const [zoom, setZoom] = useState(1);
  const [expand, setExpand] = useState(false);
  const handleZoomIn = () => setZoom((prev) => Math.min(prev + 0.1, 3));
  const handleZoomOut = () => setZoom((prev) => Math.max(prev - 0.1, 1));
  const handleResetZoom = () => {
    setZoom(1);
    setExpand(false);
  };
  const handleZoomExpand = () => {
    setZoom(1.5);
    setExpand(true);
  };
  const handleKeyDown = (event: KeyboardEvent) => {
    if (!enableZoom) return;
    if (event.key === '+' || event.key === '=' || event.key === 'ArrowUp') handleZoomIn();
    if (event.key === '-' || event.key === 'ArrowDown') handleZoomOut();
  };

  useEffect(() => {
    if (enableZoom) {
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [enableZoom]);

  return (
    <Dialog 
      fullScreen 
      open={fullscreen} 
      PaperProps={{ 
        elevation: 0 
      }}
    >
      <div style={{ position: 'relative' }}>
        {/* Close Button */}
        <IconButton
          style={{
            position: 'fixed',
            top: '10px',
            right: '10px',
            color: '#fff',
            zIndex: 1000,
            backgroundColor: 'rgba(0, 0, 0, 0.6)'
          }}
          onClick={closeFullScreen}
        >
          <CloseIcon />
        </IconButton>
        
        {/* Zoom Controls (Visible only if enableZoom is true) */}
        {enableZoom && (
          <div
            style={{
              position: 'fixed',
              bottom: '30px', 
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 1000,
              display: 'flex',
              padding: '10px',
              width: '164px',
              height: '44px',
              borderRadius: '4px',
              backdropFilter: 'blur(4px)',
              background: '#0000000D',
              justifyContent: 'space-between', 
              alignItems: 'center'
            }}
          >
            <IconButton onClick={handleZoomIn} style={zoomButtonStyle}>
              <ZoomIn />
            </IconButton>
            <IconButton onClick={handleZoomOut} style={zoomButtonStyle}>
              <ZoomOut />
            </IconButton>
            <IconButton onClick={handleResetZoom} style={zoomButtonStyle}>
              <ZoomReset />
            </IconButton>
            {/* {(expand) ? ( // commented the expand & shrink buttons
            <IconButton onClick={handleResetZoom} style={zoomButtonStyle}>
              <ZoomShrink />
            </IconButton>) : (
            <IconButton onClick={handleZoomExpand} style={zoomButtonStyle}>
              <ZoomExpand />
            </IconButton>)} */}
            
          </div>
        )}
        
        {/* Carousel Items */}
        <CarousalItems
          mediaDtos={mediaItems}
          activeIndex={activeIndex}
          sx={{
            height: '100vh',
            transform: `scale(${zoom})`,
            transformOrigin: 'center top',
            transition: 'transform 0.3s ease'
          }}
          imgSx={{ 
            height: '100vh' 
          }}
          videoSx={{ 
            height: '100vh' 
          }}
        />
      </div>
    </Dialog>
  );
};

// Common zoom button styling
const zoomButtonStyle = {
  color: '#fff',
  borderRadius: '50%',
  padding: '10px'
};

export default FullScreenView;