import { useEffect } from 'react';

interface DisableZoomProps {
    isEnabled: boolean
}

const DisableZoom: React.FC<DisableZoomProps> = ({ isEnabled }) => {

    useEffect(() => {
        const onWheelChange = (e: WheelEvent) => {
            if (e.ctrlKey) e.preventDefault();
        }

        const onGestureChange = (e: Event) => {
            e.preventDefault();
        }

        if (isEnabled) {
            document.addEventListener(`wheel`, onWheelChange, { passive: false })
            document.addEventListener(`gesturestart`, onGestureChange);
            document.addEventListener(`gesturechange`, onGestureChange);
            document.addEventListener(`gestureend`, onGestureChange);
        } else {
            document.removeEventListener(`wheel`, onWheelChange)
            document.removeEventListener(`gesturestart`, onGestureChange);
            document.removeEventListener(`gesturechange`, onGestureChange);
            document.removeEventListener(`gestureend`, onGestureChange);
        }

        return () => {
            document.removeEventListener(`wheel`, onWheelChange)
            document.removeEventListener(`gesturestart`, onGestureChange);
            document.removeEventListener(`gesturechange`, onGestureChange);
            document.removeEventListener(`gestureend`, onGestureChange);
        }

    }, [isEnabled])

    return null;

}

export default DisableZoom;